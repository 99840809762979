import smokace from '../img/smokace.png';
import Winlegends from '../img/Winlegends.png';
import CryptoLeo from '../img/CryptoLeo.png';
import mystake from '../img/mystake.png';
import csgobig from '../img/csgo_big.png';
import barz from '../img/barz.png';
import ninecasino from '../img/ninecasino.png';
import rolletto from '../img/rolletto.png'



export const Casino_list = [
    {
        "id": 1,
        "titel": 'Nine Casino',
        "img": ninecasino,
        "link": 'https://track.chillipartners.com/visit/?bta=37612&nci=5558',
        "offer1": '',
        "offer2": '1st: 100% Bonus up to 100€',
        "offer3": '2nd: 55% Bonus up to 150€',
        "offer4": '3rd: 100% Bonus up to 150€',
        "offer5": 'Wager 35x',
        "MGA": false,
        "CUR": true,
        "CR": false,
        "ES": false,
        "width": 200,
        "height":150,
    },    
    {
        "id": 3,
        "titel": 'CSGO Big',
        "img": csgobig,
        "link": 'https://csgobig.com/r/hstjorven',
        "offer1": '‎',
        "offer2": '‎',
        "offer3": '5% Deposit bonus',
        "offer4": '3 free cases on signup',
        "offer5": 'Use code: hstjorven',
        "offer6": '',
        "MGA": false,
        "CUR": true,
        "CR": false,
        "ES": false,
        "width": 200,
        "height": 100,

    },{
        "id": 4,
        "titel": 'CryptoLeo',
        "img": CryptoLeo,
        "link": 'https://track.chillipartners.com/visit/?bta=39189&brand=cryptoleo',
        "offer1": '150% Bonus up to 3000$',
        "offer2": '25% Rakeback',
        "offer3": '1 Fortune Wheel (200$ Deposit)',
        "offer4": 'Wager 35x',
        "offer5": '',
        "offer6": '',
        "MGA": false,
        "CUR": true,
        "CR": false,
        "ES": false,
        "width": 150,
        "height": 150,

    },{
        "id": 5,
        "titel": 'SmokAce',
        "img": smokace,
        "link": 'https://record.joinaff.com/_lw7ZO1hyTxOOtPKBgluVcWNd7ZgqdRLk/1/',
        "offer1": '‎',
        "offer2": '‎',
        "offer3": '100% Bonus up to 500€',
        "offer4": '100FS',
        "offer5": 'Wager 45x on bonus money',
        "offer6": '',
        "MGA": false,
        "CUR": true,
        "CR": false,
        "ES": false,
        "width": 200,
        "height": 100,

    },{
        "id": 6,
        "titel": 'WinLegends',
        "img": Winlegends,
        "link": 'https://record.joinaff.com/_lw7ZO1hyTxM0xFjfc6AwHGNd7ZgqdRLk/1/',
        "offer1": '‎',
        "offer2": '‎',
        "offer3": '100% Bonus up to 500€',
        "offer4": 'Wager 40x',
        "offer5": '',
        "offer6": '',
        "MGA": false,
        "CUR": true,
        "CR": false,
        "ES": false,
        "width": 250,
        "height": 100,

    },{
        "id": 7,
        "titel": 'MyStake',
        "img": mystake,
        "link": 'https://go.affiliatemystake.com/visit/?bta=3135057&brand=mystake',
        "offer1": '1st: 170% Crypto Bonus',
        "offer2": '2nd: 150% Bonus up to 200€',
        "offer3": '3rd: 100% Bonus up to 1000€',
        "offer4": 'Free Spins + Cashback',
        "offer5": 'Wager 30x',
        "offer6": '',
        "MGA": false,
        "CUR": true,
        "CR": false,
        "ES": false,
        "width": 200,
        "height": 150,

    }


    // }
    // ,{
    //     "id": 3,
    //     "titel": 'K8 Casino',
    //     "img": k8casino,
    //     "link": 'https://k8.io/?invite=Hstjorven123',
    //     "offer1": '100% Bonus up to 2000$',
    //     "offer2": 'No KYC without bonus',
    //     "offer3": 'Wager 35x',
    //     "offer4": '',
    //     "offer5": '',
    //     "offer6": '',
    //     "MGA": false,
    //     "CUR": true,
    //     "width": 200,
    //     "height": 160,

    // }


]
