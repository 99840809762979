import rolletto from '../img/rolletto.png';


export const BestOffer =
{
    titel: 'Rolletto',
    img: rolletto,
    link: 'https://track.rollettoaffiliates.com/visit/?bta=37838&nci=5646',
    offer1: '',
    offer2: '',
    offer3: '150% Bonus up to 500€',
    offer4: '50 FS',
    offer5: 'Wager 30x',
    "MGA": false,
    "CUR": true,
    "CR": false,
    "width": 400,
    "height":250,
    "NEW": true,
}

